import React from 'react';
import '../App.css';
import './search-compute.css';
import CSS from 'csstype';
import { AccessToken } from '../types';
import { APIPrefix } from '../functions';
import { withTranslation, WithTranslation } from "react-i18next";

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

interface ServiceTypesListProps extends WithTranslation
{

	changeServiceType: any;
	placeholderShown: boolean;
	value: string;

} // ServiceTypesListProps

interface ServiceTypesListState
{

	serviceTypesList: string[];

} // ServiceTypesListState

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	C L A S S   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

class SearchComputeServiceTypesList extends React.Component<ServiceTypesListProps, ServiceTypesListState>
{

	constructor( props:ServiceTypesListProps )
	{
		super( props );
		this.state =
		{
			serviceTypesList: []
		};
	};

	async componentDidMount()
	{
							
		var urlCommand: string = APIPrefix() + '/v1/site_capabilities/list_service_types';

		try
		{
		
			const apiResult = await fetch( urlCommand, {headers: {'Content-Type': 'application/json'}, credentials: 'include'} );


			if (apiResult.status === 200)
			{

				const returnedJson = await apiResult.json();

				// get services list.
				var serviceTypesList: any = [];
				if (returnedJson.services !== undefined)
					serviceTypesList = returnedJson.services;

				// update the state with the list of returned service types.
				this.setState( {serviceTypesList: serviceTypesList} );
			
			}
			
		}
		catch (e)
		{
		}
		
	} // componentDidMount

	//	------------------------------------------------------------
	//
	//	Handler for select box onChange event.
	//
	//	------------------------------------------------------------
	
	onChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
	{

		const value = event.target.value;
		
		// raise an onChange event.
		this.props.changeServiceType( {serviceType: value} );
				
	} // onChange
	
	render()
	{
	
		{/* main search form */}
		return	(
		    	
		    	<select	name = "lstServiceTypes"
		    			className = "service-types-listbox"
		    			multiple = {false}
		    			value = {this.props.value}
		    			onChange = {this.onChange}
		    			data-placeholder-shown = {this.props.placeholderShown ? "T" : "F"}>
		    		<option hidden value = "">{this.props.t("Select service type")}</option>
		    		<option label = "All" value = "all"> All </option>
		    		{
		    			this.state.serviceTypesList.map
		    			(
		    				(item, index) =>
		    				(
		    					<option	key = {index.toString()}
		    							label = {item}
		    							value = {item}> {item} </option>
		    				)
		    			)
		    		}
			</select> 
			
			)
			
	}

} // SearchComputeServiceTypesList

export default withTranslation() (SearchComputeServiceTypesList);
