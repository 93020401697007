

//	--------------------------------------------------------------------------
//
//	C O N S T A N T S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	T Y P E S
//
//	--------------------------------------------------------------------------

//	------------------------------------------------------------
//
//	when tasks are executed we set their refresh status, to
//	determine whether a refresh will be attempted if a token
//	was found to have expired.
//
//	------------------------------------------------------------

export enum RefreshDirective
{

	NO_REFRESH,
	REFRESH_IF_LOGGED_IN,
	REFRESH

} // RefreshDirective

//	------------------------------------------------------------
//
//	tasks that require access tokens are stored in a data structure,
//	so that if the tokens need to be refreshed the tasks can
//	be completed using the refreshed tokens.
//
//	------------------------------------------------------------

export enum TaskType
{

	NONE,
	UPDATE_SETTINGS,
	LOAD_SETTINGS,
	UPDATE_USER_SERVICE_TOKEN,
	MOVE_DATA_TO_STORAGE,
	GET_DATA_MANAGEMENT_JOBS

} // TaskType

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

//	------------------------------------------------------------
//
//	store details of the current task
//
//	------------------------------------------------------------

export interface CurrentTask
{

	taskType: TaskType;
	settings?: UserSettings;
	userServiceToken?: UserServiceToken;
	moveDataToStorage?: MoveDataToStorage;

} // CurrentTask

//	------------------------------------------------------------
//
//	parameters for launching a data-management job, moving
//	data to a storage location.
//
//	------------------------------------------------------------

export interface MoveDataToStorage
{

	toStorageAreaUUID: string;
	lifetime: number;
	filesToMove: 	{
			namespace: string,
			name: string
			}[]

} // MoveDataToStorage

//	--------------------------------------------------------------------------
//
//	controls what happens when a task finds an expired token.
//
//	--------------------------------------------------------------------------

export interface TaskDirective
{

	refreshDirective: RefreshDirective,
	retryAfterRefresh?: boolean

} // TaskDirective

//	--------------------------------------------------------------------------
//
//	update user settings
//
//	--------------------------------------------------------------------------

export interface UserSettings
{

	darkMode? : boolean;
	language?: string;

} // UserSettings

//	--------------------------------------------------------------------------
//
//	update a user service token
//
//	--------------------------------------------------------------------------

export interface UserServiceToken
{

	serviceID: string;
	usingToken: boolean;
	username: string;
	userToken: string;

} // UserServiceToken
