import React, { useState } from 'react';
import '../App.css';
import './visualisation.css';
import CSS from 'csstype';
import '../i18n'
import { useTranslation } from 'react-i18next';

// icons
import aladinIcon from '../icons/aladin-logo.512.png';
import skyServerIcon from '../icons/skyserver-logo.512.png';
import playIcon from '../icons/play-square.512.png';
import playIconDisabled from '../icons/play-square-bw.512.png';
import stopIcon from '../icons/stop-square.512.png';
import stopIconDisabled from '../icons/stop-square-bw.512.png';

// classes
import ToolSmall from '../tools/tool-small';
import VisualisationAladin from './visualisation-aladin';
import VisualisationSkyServer from './visualisation-skyserver';

//	--------------------------------------------------------------------------
//
//	T Y P E S
//
//	--------------------------------------------------------------------------

enum ToolType
{
	NONE,
	ALADIN,
	SKYSERVER
}

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	C O N S T A N T S
//
//	--------------------------------------------------------------------------
	
// define the available tools.
const TOOLS:	{
		title: string,
		code: string,
		icon: any,
		toolType: ToolType,
		toolComponent: any
		}[] =
		[
			{
			title: 'Aladin Lite',
			code: 'aladin',
			icon: aladinIcon,
			toolType: ToolType.ALADIN,
			toolComponent: VisualisationAladin
			},
			{
			title: 'SkyServer SDSS',
			code: 'skyserver',
			icon: skyServerIcon,
			toolType: ToolType.SKYSERVER,
			toolComponent: VisualisationSkyServer
			}
		];

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

//	------------------------------------------------------------
//
//	Displays the tool window for a given tool.
//
//	------------------------------------------------------------

function ToolWindow( args:	{
				toolComponent: any,
				code: string,
				running: boolean,
				params:	{
						code: string,
						launchParams: {} | null,
						params: {} | null
						}[]
				} )
{

	const Tool = args.toolComponent;
	
	var toolLaunchParams: {} | null = null;
	var toolParams: {} | null = null;
	const index = args.params.findIndex( (element) => element.code === args.code );
	if (index > -1)
	{
		toolLaunchParams = args.params[ index ].launchParams;
		toolParams = args.params[ index ].params;
	}
		
	return	(
		<Tool	key = {args.running === true ? "T" : "F"}
			running = {args.running}
			launchParams = {toolLaunchParams}
			params = {toolParams} />
		)
		
} // ToolWindow

//	------------------------------------------------------------
//
//	Displays a visualisation tool in the tools window.
//
//	------------------------------------------------------------

function VisualisationTool( args:	{
					toolTitle: string,
					icon: any,
					toolIndex: number,
					toolRunning: boolean,
					onClickHandler: any
					} )
{
			
	return	(
    	    	<div className = "visualisation-tool">
    	    		<div className = "flex-10px"></div>
    	    		<div className = "flex-row">
    	    			<div className = "flex-5px"></div>
	    	    		<img src = {args.icon} alt = "" width = "100" />
    	    			<div className = "flex-5px"></div>
	    	    		<div className = "visualisation-tool-buttons">
					<img className = "visualise-tool-button" src = {args.toolRunning === true ? playIconDisabled : playIcon}
						id = {'startTool_' + args.toolIndex.toString()} onClick = {args.onClickHandler} alt = "" width = "32" />
	    	    			<div className = "flex-expanding"></div>
					<img className = "visualise-tool-button" src = {args.toolRunning === true ? stopIcon : stopIconDisabled}
						id = {'stopTool_' + args.toolIndex.toString()} onClick = {args.onClickHandler} alt = "" width = "32" />
	    	    		</div>
    	    			<div className = "flex-5px"></div>
    	    		</div>
    	    		<div className = "visualisation-tool-title">{args.toolTitle}</div>
    	    		<div className = "visualisation-tool-status" data-running = {args.toolRunning === true ? "T" : "F"}>(running)</div>
    	    		<div className = "flex-5px"></div>
    	    	</div>
		)

} // VisualisationTool

//	--------------------------------------------------------------------------
//
//	C L A S S / M A I N   F U N C T I O N   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

export default function Visualisation( args:	{
						params: { code: string, launchParams: {} | null, params: {} | null }[]
						} )
{

  	// filter box maximised, or not?
  	const [sFilterMaximised, setFilterMaximised] = useState<boolean>( true );
  	
  	// tab information.
  	const [sTab, setTab] = useState<number>( -1 );
  	const [sTabs, setTabs] = useState<{ text: string, toolType: ToolType }[]>( [] );
  	
  	// maintain state of parameter info in order to check if something has changed.
  	//const [sParams, setParams] = useState< { code: string, launchParams: {}, params: {} }[] >( args.params );
  	
  	// tools running or not ?
  	var toolRunning: boolean[] = [];
  	for ( let i = 0; i < TOOLS.length; i++ )
  		toolRunning.push( false );
  	const [sToolRunning, setToolRunning] = useState<boolean[]>( toolRunning );

	// multi-language support
	const { i18n, t } = useTranslation();

	//	------------------------------------------------------------
	//
	//	Check if a name begins with a particular prefix, and return
	//	the remainder of the name as a string and an integer.
	//
	//	------------------------------------------------------------
	
	function getPostfix( args: { name: string, prefix: string } )
	{
	
		var postfix: { text: string, int: number } = { text: "", int: -1 };
	
		if (args.name.length > args.prefix.length)
			if (args.name.slice( 0, args.prefix.length ) === args.prefix)
			{
			
				// cut the rest of the text to get the postfix.
				postfix.text = args.name.slice( args.prefix.length - args.name.length );
				
				// convert to an integer.
				try
				{
					postfix.int = parseInt( postfix.text );
				}
				catch
				{
				}
			
			}
			
		// return something.
		return postfix;
	
	} // getPostfix

	//	------------------------------------------------------------
	//
	//	Handler for button clicks.
	//
	//	------------------------------------------------------------
  	
  	const onClickButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) =>
  	{

		const button: HTMLButtonElement = event.currentTarget;
		var postfix: { text: string, int: number };
			
		// minimise/maximise the filter box if the user clicks minimise/maximise.
		if (button.name === "minimiseFilter")
			setFilterMaximised( false );
		if (button.name === "maximiseFilter")
			setFilterMaximised( true );
			
		// changing tabs.
		postfix = getPostfix( { name: button.name, prefix: 'btnVisualisation_' } );
		if (postfix.int > -1)
			setTab( postfix.int );
		
	} // onClickButtonHandler

	//	------------------------------------------------------------
	//
	//	Handler for button clicks on image elements
	//
	//	------------------------------------------------------------
  	
  	// change handler for input boxes.
  	const onClickImgHandler = (event: React.MouseEvent<HTMLImageElement>) =>
  	{
  	
  		const button: HTMLImageElement = event.currentTarget;
		var postfix: { text: string, int: number };
			
		// starting a service
		postfix = getPostfix( { name: button.id, prefix: 'startTool_' } );
		if (postfix.int > -1)
		{
		
			// add a new tab.
			var cpTabs: { text: string, toolType: ToolType }[] = sTabs.slice();
			cpTabs.push( { text: TOOLS[ postfix.int ].title, toolType: TOOLS[ postfix.int ].toolType } );
			setTabs( cpTabs );
			setTab( cpTabs.length - 1 );
		
			// set the icons to running.
			var toolRunning: boolean[] = sToolRunning.slice();
			toolRunning[ postfix.int ] = true;
			setToolRunning( toolRunning );
			
		}
			
		// stopping a service
		postfix = getPostfix( { name: button.id, prefix: 'stopTool_' } );
		if (postfix.int > -1)
		{
		
			// remove the tab.
			var cpTabs: { text: string, toolType: ToolType }[] = sTabs.slice();
			for ( let i = 0; i < cpTabs.length; i++ )
				if (cpTabs[ i ].toolType === TOOLS[ postfix.int ].toolType)
				{
					cpTabs.splice( i, 1 );
					break;
				}
			setTabs( cpTabs );
			
			// update the selected tab index.
			var newTab: number = sTab;
			if (newTab === cpTabs.length)
				newTab = newTab - 1;
			setTab( newTab );
		
			// set the icons to not running.
			var toolRunning: boolean[] = sToolRunning.slice();
			toolRunning[ postfix.int ] = false;
			setToolRunning( toolRunning );
					
		}
  			
  	} // onClickImgHandler
  	
  	// check if any of the tools have non-null launch parameters then start the tool, or restart it with the new parameters.
  	for ( var i = 0; i < TOOLS.length; i++ )
  	{
  	
  		// check if we can find parameters for this tool in the latest parameters and the state parameters.
  		var pIndex: number = args.params.findIndex( (element) => element.code === TOOLS[ i ].code );
  		if (pIndex > -1)
  			if (args.params[ pIndex ].launchParams !== null)
  			{
  			
  				console.log( "setting tool running:" );
  				console.log( args.params[ pIndex ].launchParams );
  				console.log( args.params[ pIndex ].params );
  			
  				// check if this tool is already running.
  				if (sToolRunning[ i ] === false)
  				{
		
					// add a new tab.
					var cpTabs: { text: string, toolType: ToolType }[] = sTabs.slice();
					cpTabs.push( { text: TOOLS[ i ].title, toolType: TOOLS[ i ].toolType } );
					setTabs( cpTabs );
					setTab( cpTabs.length - 1 );
  					console.log( "tool not running" );
  				
	  				// start the tool.
					var toolRunning: boolean[] = sToolRunning.slice();
					toolRunning[ i ] = true;
					setToolRunning( toolRunning );
  					
  				}
  				else
  					console.log( "tool already running" );
  			
  			}
  	
  	}

	//	------------------------------------------------------------
	//
	//	main HTML code.
	//
	//	------------------------------------------------------------
	
	return	(
	
		<div className = "visualisation-container">
		
			{/* minimised Tools window */}
		    	<button	className = "minimised-filter"
		    			name = "maximiseFilter"
		    			type = "button"
		    			onClick = {onClickButtonHandler}
		    			data-maximised = {sFilterMaximised === true ? "T" : "F"}
		    			data-row = "T" >
		    	    	<div className = "flex-15px"></div>
		    		<div className = "maximise">&raquo;</div>
		    		<div className = "flex-row">
			    		<div className = "rotated-text-box">{t('Tools')}</div>
			    	</div>
		    	</button>
		
			{/* maximised Tools window */}
			<div className = "tool-form" data-maximised = {sFilterMaximised === true ? "T" : "F"}>
			
		    	    	<div className = "flex-15px"></div>
		    	    	<div className = "flex-row">
		    			<div className = "form-title">{t('Tools')}</div>
		    			<div className = "flex-expanding"></div>
		    			<button className = "minimise" name = "minimiseFilter" type = "button" onClick = {onClickButtonHandler}>&laquo;</button>
		    			<div style = {{ flex: '0 0 15px' }}></div>
	    			</div>
		    	    	<div className = "flex-15px"></div>
		    	    	
		    	    	{
		    	    		// display one tool component in the tools box for each available tool.
		    	    		TOOLS.map
		    	    		(
		    	    			( item, index ) =>
	    	    				(
	    	    				<VisualisationTool	key = {index}
	    	    							toolTitle = {item.title}
	    	    							icon = {item.icon}
	    	    							toolIndex = {index}
	    	    							toolRunning = {sToolRunning[ index ]}
	    	    							onClickHandler = {onClickImgHandler} />
	    	    				)
	    	    			)
		    	    	}
		    	    	
	    			<div className = "flex-15px"></div>
		    		
			</div>
			<div className = "transparent-vertical-separator"></div>
			
			<div className = "visualisation-with-tabs">
	    	
		    		{/* we have a header row containing the tabs for running tools */}
		    		<div className = "visualisation-tab-bar" data-hide = {sTabs.length === 0 ? "T" : "F"}>
		    		
		    			<div className = "flex-10px"></div>
		    			
		    			{
		    				// display one tab for each tool that is currently running.
		    				sTabs.map
		    				(
		    					( item, index ) =>
	    						(
	    						<ToolSmall	key = {index}
	    								name = {'btnVisualisation_' + index.toString()}
				    					text = {item.text}
				    					withNumber = {false}
				    					onClick = {onClickButtonHandler}
				    					selected = {sTab === index}
				    					left = {index === 0}
				    					right = {index === sTabs.length - 1} />
	    						)
		    				)
		    			}
		    			
		    		</div>
		    		
		    		{
		    			// display the tool windows for each of the tools, making them visible only if the
		    			// currently selected tab matches this tool.
		    			TOOLS.map
		    			(
		    				( item, index ) =>
	    					(
	    					<div	key = {index}
	    						className = "visualisation-section"
							data-visible = {	sTab >= 0 && sTab < sTabs.length && sTabs.length >= 1
										? (sTabs[ sTab ].toolType === item.toolType ? "T" : "F")
										: "F" }>
							<ToolWindow	toolComponent = {item.toolComponent}
									running = {sToolRunning[ index ]}
									code = {item.code}
									params = {args.params} />
						</div>
	    					)
		    			)
		    		}
			
			</div>
		
		</div>
		
		)

} // Visualisation
