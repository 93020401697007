
export type UserDataType =
{
	id: number;
	username: string;
	email: string;
	name: string;
	created: string;
	endTime: string;
	lastUpdate: string;
	memberSince: string;
	status: string;
}

export type GroupDataType =
{
	id: number;
	groupName: string;
	description: string;
	created: string;
	lastUpdate: string;
	memberSince: string;
	members: string;
	status: string;
}

export enum UserManagementPage
{

	UserManagement,
	UserEditor,
	AddUserGroups,
	GroupEditor,
	AddGroupUsers

} // UserManagementPage
