import React from 'react';
import '../App.css';
import './user-control.css';
import CSS from 'csstype';

// functions
import { APIPrefix } from '../functions';

// images

// classes
import { AccessToken } from '../types';

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

interface UserControlOIDCTokenProps
{

} // UserControlOIDCTokenProps

interface UserControlOIDCTokenState
{

	authToken: string;
	gatewayBackendToken: string;
	siteCapabilitiesToken: string;
	dataManagementToken: string;

} // UserControlOIDCTokenState

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	C L A S S   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

export default class UserControlOIDCToken extends React.Component<UserControlOIDCTokenProps, UserControlOIDCTokenState>
{

	constructor( props:UserControlOIDCTokenProps )
	{
		super( props );
		this.state =
		{
			authToken: "",
			gatewayBackendToken: "",
			siteCapabilitiesToken: "",
			dataManagementToken: ""
		};
	};

	//	------------------------------------------------------------
	//
	//	Get the oidc tokens from the backend
	//
	//	------------------------------------------------------------

	async componentDidMount()
	{
	
	  	console.log( '/get_tokens being called' );
		try
		{

  			// call the /get_tokens endpoint here.
			const apiResult = await fetch( APIPrefix() + '/v1/get_tokens',
								{headers: {'Content-Type': 'application/json'}, credentials: 'include'} );

			if (apiResult.status === 200)
			{
			
				const returnedJson = await apiResult.json();
				console.log( '/get_tokens' );
				console.log( returnedJson );

				// update state.
				this.setState(	{
						authToken: returnedJson.auth_token,
						gatewayBackendToken: returnedJson.gateway_backend_token,
						siteCapabilitiesToken: returnedJson.site_capabilities_token,
						dataManagementToken: returnedJson.data_management_token
						} );
						
			}
		
      		}
      		catch (e)
      		{
      			console.log(e);
		}
		
	} // componentDidMount

	//	------------------------------------------------------------
	//
	//	Component code
	//
	//	------------------------------------------------------------
	
	render()
	{
	
		return	(
			<div	className = "user-oidc-container"
				style = {{ width: '100%', height: '100%', borderRadius: '10px', marginRight: '5px', display: 'flex', flexDirection: 'column' }} >
				
				<div	style = {{ height: '0px', flex: '1 1 auto' }} >
				
					<div	style = {{ width: '100%', height: '100%', overflowY: 'auto' }} >
				
						<table className = "user-oidc">
							<tbody>
								<tr className="user-oidc-row">
									<td className="user-oidc-cell"><b>Auth access token:</b></td>
									<td className="user-oidc-cell">{this.state.authToken}</td>
								</tr>
								<tr className="user-oidc-row">
									<td className="user-oidc-cell"><b>Site capabilities access token:</b></td>
									<td className="user-oidc-cell">{this.state.siteCapabilitiesToken}</td>
								</tr>
								<tr className="user-oidc-row">
									<td className="user-oidc-cell"><b>Data management access token:</b></td>
									<td className="user-oidc-cell">{this.state.dataManagementToken}</td>
								</tr>
								<tr className="user-oidc-row">
									<td className="user-oidc-cell"><b>Gateway-backend access token:</b></td>
									<td className="user-oidc-cell">{this.state.gatewayBackendToken}</td>
								</tr>
							</tbody>
						</table>
						
					</div>
					
				</div>
				
			</div>
			)
		
	}

} // UserControlOIDCToken
