import React, { useState } from 'react';
import '../App.css';
import './user-control.css';
import CSS from 'csstype';
import { useTranslation } from "react-i18next";

// images
import padlockIcon from '../icons/padlock.512.png';
import userAccountIcon from '../icons/user.512.png';

// classes
import Tool from '../tools/tool';
//import UserControlLoginBox from './user-control-login-box';
import UserControlDropdownMenu from './user-control-dropdown-menu';

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

//	------------------------------------------------------------
//
//	Displays the login button with a different name depending
//	upon whether we're logged in or not.
//
//	------------------------------------------------------------

function UserAccountButton( args:	{
					loggedIn: boolean | undefined,
					buttonHandler: any,
					username: string,
					initials: string,
					userDropdownMenuDisplayed: boolean
					} )
{

	// translation function
	const { t } = useTranslation();

	if (args.loggedIn === undefined)
		return	(
				<button className = "user-control-login" name = "btnLogin" type = "button" onClick = {args.buttonHandler}>
					<div className = "user-control-login-text">{t( 'Please wait' ) + '...'}</div>
					<div className = "user-control-login-icon"><img src = {padlockIcon} alt = "" width = "16px"></img></div>
				</button>
			)
	else if (args.loggedIn === false)
		return	(
				<button className = "user-control-login" name = "btnLogin" type = "button" onClick = {args.buttonHandler}>
					<div className = "user-control-login-text">{t( 'Log in' )}</div>
					<div className = "user-control-login-icon"><img src = {padlockIcon} alt = "" width = "16px"></img></div>
				</button>
			)
	else
		return	(
				<button className = "user-control-login" name = "btnUserAccount" type = "button" onClick = {args.buttonHandler}>
					<div className = "user-control-login-text">{args.username}</div>
					<div className = "user-control-login-initials">{args.initials}</div>
					<div className = "user-control-login-dropdown">{args.userDropdownMenuDisplayed ? "ᐱ" : "ᐯ"}</div>
				</button>
			)

} // UserAccountButton

//	--------------------------------------------------------------------------
//
//	C L A S S   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

export default function UserControl( props:	{
						buttonHandler: any,
						loggedIn: boolean | undefined,
						username: string,
						userDropdownMenuDisplayed: boolean,
						setUserDropdownMenuDisplayed: any
						} )
{

	// translation function
	const { t } = useTranslation();
		
	// loop over all the characters of the username to extract the initials.
	let initials: string = "";
	let spaceLast: boolean = true;
	if (props.username !== null)
		for ( let index = 0; index < props.username.length; index++ )
		{
			if (spaceLast === true && initials.length < 3)
				initials += props.username.slice( index, index + 1 );
			spaceLast = (props.username.slice( index, index + 1 ) === " ");
		}
		
	const [sUsername, setUsername] = useState< string >( props.username !== null ? props.username : t( 'Unknown User' ) );
	const [sInitials, setInitials] = useState< string >( initials );

	//	------------------------------------------------------------
	//
	//	Component code
	//
	//	------------------------------------------------------------
		
	return	(
		<div className = "user-control">
			<div className = "flex-expander"></div>
			{
				UserAccountButton(	{
							loggedIn: props.loggedIn,
							buttonHandler: props.buttonHandler,
							username: props.username,
							initials: sInitials,
							userDropdownMenuDisplayed: props.userDropdownMenuDisplayed
							} )
			}
			<UserControlDropdownMenu	sUserDropdownMenuDisplayed = {props.userDropdownMenuDisplayed}
							setUserDropdownMenuDisplayed = {props.setUserDropdownMenuDisplayed}
							buttonHandler = {props.buttonHandler} />
			<div style = {{flex: '0 0 8px'}} />
		</div>
		)

} // UserControl
