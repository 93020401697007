import React from 'react';
import '../App.css';
import './tool.css';

// images
import spinnerDark from '../icons/spinner-dark.64.gif';
import spinnerLight from '../icons/spinner-light.64.gif';

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

interface ToolProps
{

	name: string;
	icon: any;
	text: string;
	onClick: any;
	selected?: boolean;
	loading?: boolean;
	disabled?: boolean;

} // ToolProps

interface ToolState
{

} // ToolState

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

//	------------------------------------------------------------
//
//	A highlighted tool button.
//
//	------------------------------------------------------------

function SelectedTool( args:ToolProps )
{

	return	(
	
		<div	className = "button-tabbed-container" >
			<button	name = {args.name}
					type = "button"
					className = "button-tabbed"
					data-selected = "T"
					onClick = {args.onClick} >
				<div	className = "tool-icon-container" >
					<img	src = {args.icon}
						alt = ""
						width = "22"
						height = "22" />
				</div>
				<div	className = "button-text-container" >
					<div	className = "text-container-inner" >{args.text}
							{
							args.loading === true
							?	<img	src = {spinnerDark}
									alt = ""
									width = "18"
									height = "18" />
							:	<></>
							}
					</div>
				</div>
			</button>
		</div>
	
		)

} // SelectedTool

//	------------------------------------------------------------
//
//	A non-highlighted tool button.
//
//	------------------------------------------------------------

function UnselectedTool( args:ToolProps )
{

	if (args.disabled === true)
		return	(
		
			<div	className = "button-tabbed-container" >
				<button	name = {args.name}
						type = "button"
						className = "button-tabbed"
						data-selected = "F" >
					<div	className = "tool-icon-container" >
						<img	className = "opaque-image"
							src = {args.icon}
							alt = ""
							width = "22"
							height = "22" />
					</div>
					<div	className = "button-text-container"
						data-disabled = "T" >
						<div	className = "text-container-inner" >{args.text}
							{
							args.loading === true
							?	<img	className = "image"
									src = {spinnerDark}
									alt = ""
									width = "18"
									height = "18"
									data-type = "dark" />
							:	<></>
							}
							{
							args.loading === true
							?	<img	className = "image"
									src = {spinnerLight}
									alt = ""
									width = "18"
									height = "18"
									data-type = "light" />
							:	<></>
							}
						</div>
					</div>
				</button>
			</div>
		
			)
	else
		return	(
		
			<div	className = "button-tabbed-container" >
				<button	name = {args.name}
						type = "button"
						className = "button-tabbed"
						data-selected = "F"
						onClick = {args.onClick} >
					<div	className = "tool-icon-container" >
						<img	src = {args.icon}
							alt = ""
							width = "22"
							height = "22" />
					</div>
					<div	className = "button-text-container" >
						<div	className = "text-container-inner" >{args.text}
							{
							args.loading === true
							?	<img	src = {spinnerDark}
									alt = ""
									width = "30"
									height = "30" />
							:	<></>
							}
						</div>
					</div>
				</button>
			</div>
		
			)

} // UnselectedTool

//	--------------------------------------------------------------------------
//
//	C L A S S   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

export default class Tool extends React.Component<ToolProps, ToolState>
{

	constructor( props:ToolProps )
	{
		super( props );
	};

	//	------------------------------------------------------------
	//
	//	Component code
	//
	//	------------------------------------------------------------
	
	render()
	{

		if (this.props.selected === true)
			return	(
				<SelectedTool	name = {this.props.name}
						icon = {this.props.icon}
						text = {this.props.text}
						loading = {this.props.loading}
						onClick = {this.props.onClick} />
				)
		else
			return	(
				<UnselectedTool	name = {this.props.name}
							icon = {this.props.icon}
							text = {this.props.text}
							onClick = {this.props.onClick}
							loading = {this.props.loading}
							disabled = {this.props.disabled} />
				)
			
	}

} // Tool
