import React, { useState, useEffect } from 'react';
import '../App.css';
import './preferences.css';
import CSS from 'csstype';
import { useTranslation } from "react-i18next";

// icons
import gearsIcon from '../icons/gears.gif';

// types

// types relating to tasks.
import { RefreshDirective } from '../tasks';
import { UserSettings } from '../tasks';
import { CurrentTask } from '../tasks';
import { TaskType } from '../tasks';
import { TaskDirective } from '../tasks';

// classes
import { ToolButtonType } from '../tools/tool-button';
import ToolButton from '../tools/tool-button';

//	--------------------------------------------------------------------------
//
//	T Y P E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	C L A S S   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

export default function Preferences( props:	{
						theme: string,
						setTheme: any,
						taskExecutor: any,
						updateState: any
						} )
{

	// translation function
	const { t } = useTranslation();
	
	// state variables.
	const [sDarkMode, setDarkMode] = useState< boolean >( props.theme === 'dark' );

	//	------------------------------------------------------------
	//
	//	Handler for changes to buttons
	//
	//	------------------------------------------------------------
  	
  	const onClickDivHandler = (event: React.MouseEvent<HTMLDivElement>) =>
  	{
  	
  		const divBox: HTMLDivElement = event.currentTarget;
		
		// switch between light and dark display mode.
		if (divBox.id === "lightMode")
			setDarkMode( false );
		if (divBox.id === "darkMode")
			setDarkMode( true );
  	
  	} // onClickDivHandler

	//	------------------------------------------------------------
	//
	//	Handler for clicks to buttons
	//
	//	------------------------------------------------------------
  	
  	const onClickHandler = (event: React.MouseEvent<HTMLInputElement>) =>
  	{
  	
  		const inputBox: HTMLInputElement = event.currentTarget;

		if (inputBox.name === "cancelPreferences")
			props.updateState( { setShowPreferencesDialog: false } );
			
		if (inputBox.name === "savePreferences")
		{
		
			// set the theme.
			props.setTheme( sDarkMode === true ? 'dark' : 'light' );
		
			// update the user-preferences database.
			const settings: UserSettings = { darkMode: sDarkMode };
			const currentTask: CurrentTask =	{
								taskType: TaskType.UPDATE_SETTINGS,
								settings: settings
								};
			const taskDirective: TaskDirective =	{
								refreshDirective: RefreshDirective.REFRESH_IF_LOGGED_IN,
								retryAfterRefresh: true
								}
			props.taskExecutor(	{
						currentTask: currentTask,
						taskDirective: taskDirective
						} );
		
			// close the preferences box.
			props.updateState( { setShowPreferencesDialog: false } );
		
		}
  	
  	} // onClickHandler

	//	--------------------------------------------------------------------------
	//
	//	C O M P O N E N T S
	//
	//	--------------------------------------------------------------------------

	//	------------------------------------------------------------
	//
	//	Component code
	//
	//	------------------------------------------------------------

	return	(
	
		<div	style = {{ width: '100%', height: '100%', backgroundColor: 'transparent', position: 'absolute', top: '0px', left: '0px', border: 'none', display: 'flex', flexDirection: 'row', alignItems: 'center', zIndex: '1' }} >
			<div style = {{ flex: '1 1' }} />
			<div	className = 'preferences-border'
				style = {{ flex: '7 7', height: '92%' }} >
				<div	className = 'preferences-window'
					style = {{ display: 'flex', flexDirection: 'column' }} >
					
			    		<div style = {{ flex: '0 0 15px' }} />
			    		<div className = "form-title">{t( "User Preferences" )}</div>
			    		<div style = {{ flex: '0 0 25px' }} />
			    	    	
			    	    	{/*
			    	    	//
			    	    	//	light/dark mode.
			    	    	//
			    	    	*/}
			    	    	<div	className = "set-text-colour"
			    	    		style = {{ flex: '0 0 15px', marginLeft: '15px' }} >{t( "Appearance" )}:</div>
			    	    	<div style = {{ flex: '0 0 15px' }} />
			    	    	<div style = {{ flex: '0 0 150px', display: 'flex', flexDirection: 'row' }} >
			    	    		<div style = {{ flex: '0 0 60px' }} />
			    	    		<div	id = "lightMode" 
			    	    			onClick = {onClickDivHandler}
			    	    			style = {{ flex: '0 0 250px', display: 'flex', flexDirection: 'column', border: sDarkMode === false ? '2pt solid #e70068' : '2pt solid transparent', borderRadius: '10px' }} >
				    	    		<div style = {{ flex: '1 1', width: 'auto', backgroundColor: 'white', border: '1pt solid black', borderRadius: '10px 10px 0px 0px', margin: '10px 10px 0px 10px' }} />
				    	    		<div style = {{ flex: '3 3', width: 'auto', backgroundColor: 'white', border: '1pt solid black', borderRadius: '0px 0px 10px 10px', margin: '0px 10px 0px 10px' }} />
				    	    		<div	className = "set-text-colour"
				    	    			style = {{ flex: '0 0 30px', display: 'flex', flexDirection: 'row', textAlign: 'center', alignItems: 'center', marginLeft: '5px' }} >
				    	    			<input type = "radio" value = "lightMode" checked = {sDarkMode === false} />
				    	    			{t( "Light mode" )}
				    	    		</div>
				    	    	</div>
			    	    		<div style = {{ flex: '0 0 20px' }} />
			    	    		<div	id = "darkMode"
			    	    			onClick = {onClickDivHandler} 
			    	    			style = {{ flex: '0 0 250px', display: 'flex', flexDirection: 'column', border: sDarkMode === true ? '2pt solid #e70068' : '2pt solid transparent', borderRadius: '10px' }} >
				    	    		<div style = {{ flex: '1 1', width: 'auto', backgroundColor: 'black', border: '1pt solid white', borderRadius: '10px 10px 0px 0px', margin: '10px 10px 0px 10px' }} />
				    	    		<div style = {{ flex: '3 3', width: 'auto', backgroundColor: 'black', border: '1pt solid white', borderRadius: '0px 0px 10px 10px', margin: '0px 10px 0px 10px' }} />
				    	    		<div	className = "set-text-colour"
				    	    			style = {{ flex: '0 0 30px', display: 'flex', flexDirection: 'row', textAlign: 'center', alignItems: 'center', marginLeft: '5px' }} >
				    	    			<input type = "radio" value = "darkMode" checked = {sDarkMode === true} />
				    	    			{t( "Dark mode" )}
				    	    		</div>
				    	    	</div>
			    	    	</div>
			    		
					<div style = {{ flex: '1 1' }} ></div>
					
					<div style = {{ flex: '0 0 60px', display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
						<div style = {{ flex: '1 1' }} />
			    	    		<ToolButton	key = {t("Cancel")}
			    	    				name = "cancelPreferences"
			    	    				onClick = {onClickHandler}
			    	    				text = {t("Cancel")}
			    	    				type = {ToolButtonType.PRIMARY} />
						<div style = {{ flex: '0 0 15px' }} />
			    	    		<ToolButton	key = {t("Save")}
			    	    				name = "savePreferences"
			    	    				onClick = {onClickHandler}
			    	    				text = {t("Save")}
			    	    				type = {ToolButtonType.PRIMARY} />
						<div style = {{ flex: '0 0 15px' }} />
					</div>
					
				</div>
    			</div>
    			<div style = {{ flex: '1 1' }} />
    		</div>
		
		)

} // Preferences
