import i18n from "i18next";
import i18nBackend from "i18next-http-backend";
import useLocalStorage from 'use-local-storage';
import { initReactI18next } from "react-i18next";

// functions
import { GetURL } from './functions';
import { StripDoubleQuotes } from './functions';
	
//	-------------------------------------------------
//
//	Chooses between a localhost path and production service path,
//	depending up what is currently running.
//
//	-------------------------------------------------

const getCurrentHost: string = GetURL();
const language: string = StripDoubleQuotes( { value: localStorage.getItem( 'gateway_language' ) === null ? 'en' : localStorage.getItem( 'gateway_language' ) } );
console.log( "using language file: " + getCurrentHost + '/i18n/' + language + '.json' );

i18n
	.use(i18nBackend)
	.use(initReactI18next)
	.init(	{
		fallbackLng: "en",
		lng: language,
		interpolation:	{
				escapeValue: false,
				},
		backend:	{
				loadPath: `${getCurrentHost}/i18n/{{lng}}.json`,
				},
		} );

export default i18n;
