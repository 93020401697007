import React, { useState, useEffect } from 'react';
import '../App.css';
import './user-management.css';
import CSS from 'csstype';
import { APIPrefix } from '../functions';
import { AccessToken } from '../types';
import { useTranslation } from "react-i18next";

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	C L A S S   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

export default function UserManagementGroupList( props:	{
								changeGroup: any,
								placeholderShown: boolean,
								value: string
								} )
{

	// translation function
	const { t } = useTranslation();
	
	const [sGroupList, setGroupList] = useState< string[] >( [] );
	
  	useEffect	( () =>
		  	{
				
				// load the namespace data from the data-management API.
				loadGroups();
				
			}, []
			);

	//	------------------------------------------------------------
	//
	//	An asynchronous function that loads the group data
	//	from the ? API.
	//
	//	------------------------------------------------------------
  	
  	async function loadGroups()
  	{
							
		//var urlCommand: string = APIPrefix() + '/v1/data_management/list_namespaces';

		//try
		//{
		
		//	const apiResult = await fetch( urlCommand,	{
		//							headers: {'Content-Type': 'application/json'},
		//							credentials: 'include'
		//							} );

			// Return code 200 means the API has run.
		//	if (apiResult.status === 200)
		//	{

		//		const returnedJson = await apiResult.json();
		//		console.log( "/list_namespaces" );
		//		console.log( returnedJson );

				// get namespaces list.
		//		var namespaceList: any = [];
		//		if (returnedJson.namespaces !== undefined)
		//			namespaceList = returnedJson.namespaces;

				// update the state with the list of returned sites.
		//		setNamespaceList( namespaceList );
			
		//	}
			
		//}
		//catch (e)
		//{
		//}
		
		const groupList: string[] =	[
							'group1',
							'group2',
							'group3'
						]
		setGroupList( groupList );
  	
  	} // loadGroups

	//	------------------------------------------------------------
	//
	//	Handler for select box onChange event.
	//
	//	------------------------------------------------------------
	
	const onChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
	{

		const value = event.target.value;
		
		// raise an onChange event.
		props.changeGroup( {group: value} );
				
	} // onChange
	
	return	(
	    	
	    	<select	name = "lstGroup"
	    			className = "listbox"
	    			multiple = {false}
	    			value = {props.value}
	    			onChange = {onChange}
	    			data-placeholder-shown = {props.placeholderShown ? "T" : "F"}>
	    		<option hidden value = "">{t("Select group")}</option>
	    		{
	    			sGroupList.map
	    			(
	    				item =>
	    				(
	    					<option	key = {item}
	    							label = {item}
	    							value = {item} > {item} </option>
	    				)
	    			)
	    		}
		</select> 
		
		)

} // UserManagementGroupList
