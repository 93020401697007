import React, { useState, useEffect } from 'react';

//	------------------------------------------------------------
//
//	Add the SKAO Rucio catalog as the requested position.
//
//	------------------------------------------------------------
		
export function addCatalog( props:	{
					aladin: any,
					ra: number,
					dec: number,
					selectedID: string[],
					setSelectedID: any
					} )
{

	var win: any = window;
	
	// remove any existing layers.
	props.aladin.removeLayers();
								
	// add the SKAO Rucio catalog.			
	win.A.catalogFromSKAORucio(	props.ra.toString() + " " + props.dec.toString(),
					90,
					{onClick: 'showTable'},
					(cat: any) =>
					{
					
						// set the hover colour to white so that highlighted objects are highly visible.
						cat.setHoverColor( '#ffffff' );
					
						console.log( "cat:" );
						console.log( cat );
						
						// select the source that the user has chosen on the search-data screen.
						props.aladin.addCatalog( cat );
						
						// check if some of these footprints/sources should be highlighted.
						if (props.setSelectedID !== undefined)
						{
						
							// select a source.
							var selectedID: { id: string, ra: number, dec: number }[] = [];
							if (props.selectedID.length > 0)
								for ( var i: number = 0; i < cat.sources.length; i++ )
									for ( var j: number = 0; j < props.selectedID.length; j++ )
										if (cat.sources[ i ].data.obs_publisher_did === props.selectedID[ j ])
										{
											cat.sources[ i ].actionClicked();
											selectedID.push(	{
														id: cat.sources[ i ].data.obs_publisher_did,
														ra: cat.sources[ i ].ra,
														dec: cat.sources[ i ].dec 
														} );
										}
										
							// update selected sources.
							props.setSelectedID( selectedID );
						
						}
								
					} );

} // addCatalog

//	------------------------------------------------------------
//
//	Move the Aladin view to another location, and maybe
//	reload the catalog.
//
//	------------------------------------------------------------
				
export function moveAladinView( props:	{
						aladin: any,
						ra: number,
						dec: number,
						fov: number,
						selectedID: string[],
						setSelectedID: any,
						loadCatalog: boolean
						} )
{
	
	// update the position of the view.
	props.aladin.setFov( props.fov );
	props.aladin.gotoRaDec( props.ra, props.dec );
					
	// add the SKAO Rucio catalog.
	if (props.loadCatalog === true)
		addCatalog(	{
				aladin: props.aladin,
				ra: props.ra,
				dec: props.dec,
				selectedID: props.selectedID,
				setSelectedID: props.setSelectedID
				} );

} // moveAladinView

//	------------------------------------------------------------
//
//	Place an Aladin Lite component on the page
//
//	Optional parameters:
//
//		[selectedID, setSelectedID] - a list of selected footprints/sources
//		highlightedID - the ID of a footprint/source that should be hovered
//		setAladinRefresh - number state. will be updated to a random value
//					to force the page to rerender.
//
//	------------------------------------------------------------
				
export function Aladin( props:	{
					divElementID: string,
					aladin: any,
					setAladin: any,
					selectedID?:	{
							id: string,
  							ra: number,
  							dec: number
  							}[] | undefined,
					setSelectedID?: any,
					highlightedID?: string | undefined,
					setHighlightedID?: any,
					setAladinRefresh?: any,
					updateAladinPositionRaiseEvent?: any,
					setLoadingComponent: any,
					running: boolean
					} )
{

	//	-------------------------------------------------
	//
	//	C O N S T A N T S
	//
	//	-------------------------------------------------

	//const ALADIN_URL: string = "https://aladin.u-strasbg.fr/AladinLite/api/v2/latest/aladin.min.js";
	//const ALADIN_URL: string = "https://aladin.cds.unistra.fr/AladinLite/api/v3/latest/aladin.js";
	//const ALADIN_URL: string = "https://aladin.cds.unistra.fr/AladinLite/api/v3/3.0.9/aladin.js";
	//const ALADIN_URL: string = "https://aladin.cds.unistra.fr/AladinLite/api/v3/3.4.0-beta/aladin.js";
	//const ALADIN_URL: string = "https://aladin.cds.unistra.fr/AladinLite/api/v3/3.4.1-beta/aladin.js";
	//const ALADIN_URL: string = "https://aladin.cds.unistra.fr/AladinLite/api/v3/beta/aladin.js";
	const ALADIN_URL: string = "https://aladin.cds.unistra.fr/AladinLite/api/v3/latest/aladin.js";
		
	// populate a list of HiPS to be displayed in Aladin.
	const HIPS_LIST =	[
									
				// high energy (gamma and X-rays)
				'CDS/P/HGPS/Flux',
				'CDS/P/Fermi/5',
				'CDS/P/Fermi/4',
				'CDS/P/Fermi/3',
				'ov-gso/P/Fermi/Band2',
				'ov-gso/P/BAT/150-195keV',
				'ov-gso/P/BAT/35-50keV',
				'ov-gso/P/BAT/14-20keV',
				'erosita/dr1/rate/023',
				'erosita/dr1/rate/024',
				
				// uv/optical/infrared
				'CDS/P/GALEXGR6_7/FUV',
				'CDS/P/GALEXGR6_7/NUV',
				'CDS/P/DSS2/color',
				'CDS/P/PanSTARRS/DR1/g',
				'CDS/P/PanSTARRS/DR1/r',
				'CDS/P/Finkbeiner',
				'CDS/P/PanSTARRS/DR1/i',
				'CDS/P/PanSTARRS/DR1/color-i-r-g',
				'CDS/P/PanSTARRS/DR1/z',
				'CDS/P/PanSTARRS/DR1/y',
				'CDS/P/DES-DR2/ColorIRG',
				'CDS/P/2MASS/color',
				'ov-gso/P/GLIMPSE/irac1',
				'ov-gso/P/GLIMPSE/irac2',
				'CDS/P/unWISE/color-W2-W1W2-W1',
				'ov-gso/P/GLIMPSE/irac3',
				'ov-gso/P/GLIMPSE/irac4',
				'CDS/P/IRIS/color',
				'ESAVO/P/AKARI/N60',
				'ESAVO/P/AKARI/WideL',
				'ESAVO/P/HERSCHEL/SPIRE-250',
				'ESAVO/P/HERSCHEL/SPIRE-350',
				'ESAVO/P/HERSCHEL/SPIRE-500',
				
				// sub-mm/mm/radio
				'CDS/P/PLANCK/R3/HFI/color',
				'CDS/P/ACT_Planck/DR5/f220',
				'CDS/P/CO',
				'CDS/P/PLANCK/R3/HFI100',
				'CDS/P/PLANCK/R3/LFI30',
				'CDS/P/NVSS',
				'CSIRO/P/RACS/mid/I',
				'ov-gso/P/CGPS/VGPS',
				'CDS/C/HI4PI/HI',
				'CDS/P/MeerKAT/Galactic-Centre-1284MHz-StokesI',
				'CSIRO/P/RACS/low/I',
				'astron.nl/P/tgssadr',
				'ov-gso/P/GLEAM/170-231',
				'ov-gso/P/GLEAM/139-170',
				'astron.nl/P/lotss_dr2_high',
				'ov-gso/P/GLEAM/103-134',
				'ov-gso/P/GLEAM/072-103'
														
				];

	//	-------------------------------------------------
	//
	//	state variables
	//
	//	-------------------------------------------------
	
	// hold position and field-of-view
	const [sPosition, setPosition] = useState< { ra: number, dec: number } >( { ra: 0.0, dec: 0.0 } );
	const [sFOV, setFOV] = useState< number >( 5.0 );

	//	-------------------------------------------------
	//
	//	F U N C T I O N S
	//
	//	-------------------------------------------------
  	

	//	------------------------------------------------------------
	//
	//	Check if the script has already been added to the page, and
	//	if not add it.
	//
	//	------------------------------------------------------------
	
  	useEffect	( () =>
		  	{

				// Load Aladin A.js script.
				if (isScriptOnPage( { url: ALADIN_URL } ) === false)
				{
					const script = document.createElement( "script" );
					script.src = ALADIN_URL;
					script.charset = "utf-8";
					script.async = true;
					script.onload = scriptLoaded;
					document.body.appendChild( script );
				}
				
			}, []
			);

	//	------------------------------------------------------------
	//
	//	Check if a particular script has already been added to the
	//	page.
	//
	//	------------------------------------------------------------
    					
	function isScriptOnPage( args: { url: string } )
	{
	
		var scriptFound: boolean = false;
		
    		var scripts = document.getElementsByTagName( 'script' );
    		for ( var i = scripts.length; i--; )
			if (scripts[ i ].src === args.url)
				scriptFound = true;
				
		// return something.
		return scriptFound;
		
	} // isScriptOnPage

	//	------------------------------------------------------------
	//
	//	Check if a particular script has been added to the
	//	page, and if so remove it.
	//
	//	------------------------------------------------------------
    					
	function removeScript( args: { url: string } )
	{
	
		var scriptID: number = -1;
		
    		var scripts = document.getElementsByTagName( 'script' );
    		for ( var i = scripts.length; i--; )
			if (scripts[ i ].src === args.url)
				scriptID = i;
				
		// remove the script.
		if (scriptID > -1)
		{
			const script: any = scripts[ scriptID ];
			if (script !== null)
				if (script.parentNode !== null)
					script.parentNode.removeChild( script );
		}
		
	} // removeScript

	//	------------------------------------------------------------
	//
	//	This function fires once the Aladin script has been
	//	loaded.
	//
	//	------------------------------------------------------------

	function scriptLoaded()
	{
		
		// remove the loading-component graphic.
		props.setLoadingComponent( false );
		
		// start Aladin initially at RA 0, dec 0.
		startAladin(	{
				ra: 0.0,
				dec: 0.0,
				fov: 5.0,
				selectedID: [],
				highlightedID: '',
				loadCatalog: true
				} );
		
	} // scriptLoaded

	//	------------------------------------------------------------
	//
	//	Initialise Aladin Lite.
	//
	//	------------------------------------------------------------
					
	function startAladin( args:	{
					ra: number,
					dec: number,
					fov: number,
					selectedID: string[],
					highlightedID: string,
					loadCatalog: boolean
					} )
	{

		const win: any = window;
		
		// v3 of the API.
		let aladin: any;
		win.A.init.then( () =>
			{
			
				try
				{
					
					aladin = win.A.aladin(	'#' + props.divElementID,
								{
								fov: args.fov,
								target: args.ra.toString() + " " + args.dec.toString(),
								projection: 'AIT',
								showContextMenu: true,
								hipsList: HIPS_LIST
								} );
									
					// store the Aladin session in state.
					props.setAladin( aladin );
						
					// add the on-click event to sources, so the selected object state is updated when something is clicked.
					if (props.setSelectedID !== undefined)
						aladin.on(	'objectClicked',
								function( object: any )
								{
								
									var selectedID: { id: string, ra: number, dec: number }[] = [];
									
									if (object)
									{
										
										if ("data" in object)
											selectedID.push(	{
														id: object.data.obs_publisher_did,
														ra: object.ra,
														dec: object.dec
														} );
										else if ("source" in object)
											if ("data" in object.source)
												selectedID.push(	{
															id: object.source.data.obs_publisher_did,
															ra: object.source.ra,
															dec: object.source.dec
															} );
									}
									
									// update selected IDs.
									props.setSelectedID( selectedID );
								
								} );
							
					// add the hovered event to sources, so the highlighted-object state is updated when something is hovered over.
					if (props.setHighlightedID !== undefined)
						aladin.on(	'objectHovered',
								function( object: any )
								{
								
									if (object)
									{
									
										// get the obs_publisher_id, either from object.data, or if it's a footprint
										// then get it from .source.data
										if ("data" in object)
											props.setHighlightedID( object.data.obs_publisher_did );
										else if ("source" in object)
											if ("data" in object.source)
												props.setHighlightedID( object.source.data.obs_publisher_did );
										
									}
									else
										props.setHighlightedID( '' );
								
								} );
							
					// add the hovered stop event to sources, so the highlighted-object state is reset when something is no longer hovered over.
					if (props.setHighlightedID !== undefined)
						aladin.on(	'objectHoveredStop',
								function( object: any )
								{
								
									props.setHighlightedID( '' );
									
								} );
							
					// add the select event so that if multiple objects are selected then the state is updated.
					if (props.setSelectedID !== undefined)
						aladin.on(	'select',
								function( object: any )
								{
									
									// build a list of selected items.
									var selectedID: { id: string, ra: number, dec: number }[] = [];
									
									if (object)
										if (object.length > 0)
										{
											const sources: any = object[ 0 ];
											for ( var i = 0; i < sources.length; i++ )
											{
											
												if ("data" in sources[ i ])
													selectedID.push(	{
																id: sources[ i ].data.obs_publisher_did,
																ra: sources[ i ].ra,
																dec: sources[ i ].dec
																} );
												else if ("source" in sources[ i ])
													if ("data" in sources[ i ].source)
														selectedID.push(	{
																	id: sources[ i ].source.data.obs_publisher_did,
																	ra: sources[ i ].source.ra,
																	dec: sources[ i ].source.dec
																	} );
											}
										}
									
									// update selected IDs.
									props.setSelectedID( selectedID );
								
								} );
							
					// add the click event so that the state is updated to trigger a refresh when Aladin is clicked. we need to do
					// this so that we can clear the selection in state when the selection is cleared in Aladin.
					if (props.setAladinRefresh !== undefined)
						aladin.on(	'click',
								function( object: any )
								{

									props.setAladinRefresh( Math.random() );
								
								} );
							
					// check for the position of the Aladin view changing.
					aladin.on(	'positionChanged',
							function( object: any )
							{
							
								// build the new position.
								const newPosition: { ra: number, dec: number } =
									{
									ra: object.ra,
									dec: object.dec
									}
									
								// update the state.
								setPosition( newPosition );
							
							} );
							
					// check for the zoom of the Aladin view changing.
					aladin.on(	'zoomChanged',
							function( object: any )
							{
								
								// update the state.
								setFOV( object );
							
							} );

					console.log( 'sAladin:' );
					console.log( aladin );
									
					// add the SKAO Rucio catalog.
					addCatalog(	{
							aladin: aladin,
							ra: args.ra,
							dec: args.dec,
							selectedID: args.selectedID,
							setSelectedID: props.setSelectedID
							} );
											
				}
				catch
				{
				}
									
			}
		);

	} // startAladin

	//	-------------------------------------------------
	//
	//	C O M P O N E N T   C O D E
	//
	//	-------------------------------------------------
	
	if (props.aladin !== null && props.running === true)
	{
						
		if (props.aladin.view.catalogs.length > 0 && props.highlightedID !== undefined)
			for ( var catIndex: number = 0; catIndex < props.aladin.view.catalogs.length; catIndex++ )
			{
	
				// loop through all sources, checking if the ID matches the ID of the currently highlighted row. If so, set the
				// hover method.		
				const cat: any = props.aladin.view.catalogs[ catIndex ];
				for ( var i: number = 0; i < cat.sources.length; i++ )
					if (cat.sources[ i ].data.obs_publisher_did === props.highlightedID && props.highlightedID !== '')
						cat.sources[ i ].hover();
					else
						cat.sources[ i ].unhover();
				for ( var i: number = 0; i < cat.footprints.length; i++ )
					if (cat.footprints[ i ].source.data.obs_publisher_did === props.highlightedID && props.highlightedID !== '')
						cat.footprints[ i ].hover();
					else
						cat.footprints[ i ].unhover();
						
			}
	
	}

	//	------------------------------------------------------------
	//
	//	Kick off some processes once the page has loaded.
	//
	//	------------------------------------------------------------
	
	useEffect	( () =>
			{
				
				// remove selection if nothing is selected.
				if (props.aladin !== null)
					if ('selection' in props.aladin.view && props.selectedID !== undefined && props.setSelectedID !== undefined)
						if (props.aladin.view.selection === null && props.selectedID.length > 0)
							props.setSelectedID( [] );
	
				// remove the Aladin script from the page body if it exists. We will re-add it next time the tool is run so that the
				// scriptLoaded() function will fire again.
				if (props.running === false)
				{
					removeScript( { url: ALADIN_URL } );
					props.setAladin( null );
				}
			
			}, []
			);
			
	useEffect	( () =>
			{
			
				// raise an event if the position changes due to a drag movement in the Aladin window.
				if (props.updateAladinPositionRaiseEvent !== undefined)
					props.updateAladinPositionRaiseEvent( { position: sPosition } );
			
			}, [sPosition]
			);
			
	useEffect	( () =>
			{
			
				// raise an event if the field-of-view changes due to a scroll in the Aladin window.
				if (props.updateAladinPositionRaiseEvent !== undefined)
					props.updateAladinPositionRaiseEvent( { fov: sFOV } );
			
			}, [sFOV]
			);

	//if (props.running === true)
		return	(
		
			<div id = {props.divElementID} style = {{ flex: '1 1', display: props.running === true ? 'flex' : 'none', height: '100%', width: '100%' }} />
		
			)
	//else
	
	//	return	(
	//		<></>
	//		)

} // Aladin
