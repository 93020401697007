import React, { useState, useRef, Ref, useEffect } from 'react';

//	-------------------------------------------------
//
//	P U B L I C   F U N C T I O N S
//
//	-------------------------------------------------

// ------------------------------------------------------------
//
//	Determines the prefix to apply to all API calls.
//
// ------------------------------------------------------------

export function APIPrefix()
{
					
	var currentUrl: string = GetURL();
	var addPort: boolean = false;
	
	// check if there is a port number at the end.
	if (nthIndexOf( { inputString: currentUrl, findString: ':', itemNumber: 2 } ) >= 0)
	{
		currentUrl = currentUrl.slice( 0, nthIndexOf( { inputString: currentUrl, findString: ':', itemNumber: 2 } ) );
		addPort = true;
	}
	
	// if we're using localhost then we've probably got a port number (likely 3000), which should be replaced with port 8080.
	// if we're on one of the production servers then there is no port number included, but we need to append '/tannet-api' to reach the APIs.
	var apiPrefix: string = currentUrl;
	if (addPort === true)
		apiPrefix = apiPrefix + ':8080';
	else
		apiPrefix = apiPrefix + '/tannet-api';
		
	// return something.
	return apiPrefix;

} // APIPrefix

// ------------------------------------------------------------
//
//	Checks if there has been any overflow in a DIV element
//	containing text.
//
// ------------------------------------------------------------

export function CheckOverflow( args:	{
					ref: any,
					text: string
					} )
{
	
	const [sIsOverflow, setIsOverflow] = useState< boolean | undefined >( undefined );
	const [sTextLastCheck, setTextLastCheck] = useState< string | undefined >( undefined );

	React.useLayoutEffect	(	() =>
					{
						
						const { current } = args.ref;
						const { clientWidth, scrollWidth, clientHeight, scrollHeight } = current;

						if (current)
						{
						
							//if (args.text[ 0 ] === 'Z')
							//{
							//	console.log( "CheckOverflow size:" );
							//	console.log( args.text );
							//	console.log( scrollHeight );
							//	console.log( scrollWidth );
							//	console.log( clientHeight );
							//	console.log( clientWidth );
							//}

							// an overflow has occurred if we need to scroll in either the X or Y direction.
							const hasOverflow = (scrollHeight > clientHeight || scrollWidth > clientWidth);

							// update the state. we need to update the last-check value as well, in order that the process to shorted the
							// text can be stopped and the screen re-rendered.
							setIsOverflow( hasOverflow );
							setTextLastCheck( args.text );
								
						}
							
					}, [args.ref, args.text]
				);
	
	//if (args.text[ 0 ] === 'Z')
	//{
	//	console.log( "CheckOverflow:" );
	//	console.log( args.text );
	//	console.log( sTextLastCheck );
	//	console.log( sIsOverflow );
	//}

	// return something.
	return sIsOverflow && args.text === sTextLastCheck;
  
} // CheckOverflow

// ------------------------------------------------------------
//
//	Displays a file size in either bytes, 
//
// ------------------------------------------------------------

export function DisplayFileSize( args:	{
						bytes: number
						} )
{

	var textValue: string = "";
	
	if (args.bytes !== null)
	{
	
		if (args.bytes < 1024)
			textValue = args.bytes.toFixed( 2 ) + " B";
		else if (args.bytes < (1024 * 1024))
			textValue = (args.bytes / 1024).toFixed( 2 ) + " KB";
		else if (args.bytes < (1024 * 1024 * 1024))
			textValue = (args.bytes / (1024 * 1024)).toFixed( 2 ) + " MB";
		else if (args.bytes < (1024 * 1024 * 1024 * 1024))
			textValue = (args.bytes / (1024 * 1024 * 1024)).toFixed( 2 ) + " GB";
		else
			textValue = (args.bytes / (1024 * 1024 * 1024 * 1024)).toFixed( 2 ) + " TB";
	
	}
	else
		textValue = 'size is null';
	
	// return something.
	return textValue;

} // DisplayFileSize

// ------------------------------------------------------------
//
//	Gets the current URL. i.e. https://domain:port
//
// ------------------------------------------------------------

export function GetURL()
{
			
	// get the base URL.
	var url: string = document.location.href;
	if (nthIndexOf( { inputString: url, findString: '/', itemNumber: 3 } ) > 0)
		url = url.slice( 0, nthIndexOf( { inputString: url, findString: '/', itemNumber: 3 } ) );
		
	// return something.
	return url;

} // GetURL

// ------------------------------------------------------------
//
//	Monitors for mouse clicks so that a dropdown menu
//	attached to {ref} can be closed.
//
// ------------------------------------------------------------

export function HideDropdownMenu( args:	{
						sDropdownDisplayed: boolean,
						setDropdownDisplayed: any
						} )
{

	const ref = useRef<HTMLDivElement>( null );

	// ------------------------------------------------------------
	//
	//	handle click events, and check if we've clicked on
	//	the dropdown menu, or the previous cell, which should
	//	be the dropdown button.
	//
	// ------------------------------------------------------------

	const handleClickOutside = (event: any) =>
	{
		
		var currentCell: boolean = false;
		var previousCell: boolean = false;
        	if (ref.current)
        	{
        		currentCell = (ref.current.contains( event.target ));
        		if (ref.current.previousSibling)
	        		previousCell = (ref.current.previousSibling.contains( event.target ));
        	}
        	if (currentCell === false && previousCell === false)
			args.setDropdownDisplayed( false );
			
	};

	useEffect
	(
		() =>
		{
			document.addEventListener( 'click', handleClickOutside, true );
        		return	() =>
        			{
					document.removeEventListener('click', handleClickOutside, true);
				};
		}, []
	);

	return { ref };
    
} // HideDropdownMenu

// ------------------------------------------------------------
//
//	Reduce a text string by one character.
//	We add the dots (...) to the section where the
//	character was removed.
//	We don't take off numbers on the right-hand side.
//
// ------------------------------------------------------------

export function ShortenText( args:	{
					text: string,
					setText: any,
					setTextWithoutDots: any
					} )
{
		
	// shrink the text if there is an overflow.
	var text: string = args.text;
	const len: number = text.length;
	
	// look for the first non-numeric character from the right.
	var foundChar: boolean = false;
	var newText: string = '';
	var newTextWithoutDots: string = '';
	if (len > 0)
	{
		for ( var i = len - 1; i >= 0; i-- )
		{
		
			var alpha: boolean = (text[ i ] >= 'a' && text[ i ] <= 'z');
			if (alpha === false || foundChar === true)
			{
				newText = text[ i ] + newText;
				newTextWithoutDots = text[ i ] + newTextWithoutDots;
			}
			if (alpha === true && foundChar === false)
			{
				newText = '...' + newText;
				foundChar = true;
			}
		
		}
		if (newText !== text)
		{
			args.setText( newText );
			args.setTextWithoutDots( newTextWithoutDots );
		}
	}

	// return something.

} // ShortenText
	
//	-------------------------------------------------
//
//	Removes double quotes from around a string.
//
//	-------------------------------------------------
				
export function StripDoubleQuotes( args:	{
						value: string | null
						} )
{

	var outValue: string = '';
	if (args.value !== null)
		if (args.value[ 0 ] === '\"' && args.value[ args.value.length - 1 ] === '\"')
			outValue = args.value.slice( 1, args.value.length - 1 );
		
	// return something.
	return outValue;

} // StripDoubleQuotes

//	-------------------------------------------------
//
//	Function to check Session Validity
//
//	-------------------------------------------------

export async function hasSessionExpired(){
	const response = await fetch(APIPrefix() + '/v1/check_session', {
		credentials: 'include'
	});
	const gateway_session = await response.json();
	
	return gateway_session.is_valid;
}

//	-------------------------------------------------
//
//	P R I V A T E   F U N C T I O N S
//
//	-------------------------------------------------

//	-------------------------------------------------
//
//	find the position of the nth occurrence of a
//	substring within a string.
//
//	-------------------------------------------------

const nthIndexOf = function( args:	{
					inputString: string,
					findString: string,
					itemNumber: number
					} )
{

	var i: number = -1;
	var n: number = args.itemNumber;
	while (n-- && i++ < args.inputString.length)
	{
		i = args.inputString.indexOf( args.findString, i );
		if (i < 0) break;
	}
	
	return i;

} // nthIndexOf
