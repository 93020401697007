import React, { useState, useRef, Ref, useEffect } from 'react';
import '../App.css';
import './user-management.css';
import CSS from 'csstype';
import { useTranslation } from "react-i18next";

// icons
import gearsIcon from '../icons/gears.gif';

// types
import { AccessToken } from '../types';
import { UserManagementPage } from './types';

// functions
import { APIPrefix } from '../functions';

// classes
import { ToolButtonType } from '../tools/tool-button';
import ToolButton from '../tools/tool-button';
import ToolSmall from '../tools/tool-small';
import UserManagementUsers from './user-management-users';
import UserManagementGroups from './user-management-groups';
import SearchResultsFooter from '../tools/search-results/search-results-footer';

//	--------------------------------------------------------------------------
//
//	T Y P E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

// ------------------------------------------------------------
//
//	Display the results tabs at the top of the screen.
//
// ------------------------------------------------------------

function TabBar( args:	{
			tabID: string,
			tabLabel: { value: string, index: number }[],
			selectedTab: number,
			totalCount: number[],
			onClickHandler: any,
			tabDropdownDisplayed: boolean,
			setTabDropdownDisplayed: any,
			pages: number
			} )
{

	// translation function
	const { t } = useTranslation();
 
	// maximum number of tabs that can be displayed along the tab bar.
	const [sMaxTabs, setMaxTabs] = useState<number>( -1 );
	const [sTabWidth, setTabWidth] = useState<number>( 262 );
	const [sDropdownWidth, setDropdownWidth] = useState<number>( 40 );
	const [sFirstTab, setFirstTab] = useState<number>( 0 );
	
	// handle changes in size to the tab bar.
	const resizeTabBar =	React.useRef<ResizeObserver>
				(
					new ResizeObserver
					(
						(entries:ResizeObserverEntry[]) =>
						{
						
							// recalculate the number of tabs that we can display along the top. any other tabs
							// must be placed in a drop-down menu.
							if (entries[0].contentRect.width > 0)
							{
		  						const maxTabs: number = Math.floor( (entries[0].contentRect.width - 350) / 262 );
		  						const tabWidth: number = Math.floor( (entries[0].contentRect.width - 350) / maxTabs );
		  						const dropdownWidth: number = entries[0].contentRect.width - 310 - (maxTabs * tabWidth);
								setMaxTabs( maxTabs );
								setTabWidth( tabWidth );
								setDropdownWidth( dropdownWidth );
							}
							
						}
					)
				);

	// create a reference to the HTML Div element that contains the tab bar, so that we can monitor for changes in size.
	const tabButtons =	React.useCallback
				(
					(container: HTMLDivElement) =>
					{
						
						// check that this reference has actually been assigned to an element, and switch on observing.
    						if (container !== null)
        						resizeTabBar.current.observe( container );
    							
						// When element is unmounted, ref callback is called with a null argument
						// => best time to cleanup the observer
    						else
        						if (resizeTabBar.current)
            							resizeTabBar.current.disconnect();
    							
					}, [resizeTabBar.current]
				);
							
	// check if we need to hide the drop-down menu.
	if (args.tabDropdownDisplayed === true && sMaxTabs >= args.tabLabel.length)
		args.setTabDropdownDisplayed( false );
		
	// change the first tab displayed in the list to ensure that the selected tab is displayed.
	{
		var firstTab: number = sFirstTab;
		if (args.selectedTab > -1)
		{
			if (args.selectedTab < firstTab)
				firstTab = args.selectedTab;
			if (args.selectedTab >= firstTab + sMaxTabs)
				firstTab = args.selectedTab - sMaxTabs + 1;
		}
		if (args.tabLabel.length - firstTab - 1 < sMaxTabs)
			firstTab = args.tabLabel.length - sMaxTabs;
		if (firstTab < 0)
			firstTab = 0;
		if (firstTab != sFirstTab)
			setFirstTab( firstTab );
	}

	return	(
	
    		/* we have a header row containing the tabs for the search form and (multiple) results */
    		<div ref = {tabButtons} className = "search-results-tab-bar" data-row-flex = "T">
		    				
		    	{/* we have the results tabs */}	
			{
				args.tabLabel.filter
				(
					(tab) => tab.index >= sFirstTab && tab.index < sFirstTab + sMaxTabs
				).map
				(
					(tab) =>
					(
						<ToolSmall	key = {'tool_' + tab.index.toString()}
								name = {"btnTab_" + args.tabID + "_tab" + tab.index.toString()}
								text = {tab.value !== '' ? t( tab.value ) : '<none>'}
								withNumber = {true}
								count = {args.totalCount[ tab.index ]}
								onClick = {args.onClickHandler}
								selected = {args.selectedTab === tab.index}
								left = {tab.index === 0}
								right = {tab.index === args.tabLabel.length - 1 || tab.index === sFirstTab + sMaxTabs - 1}
								tabWidth = {sTabWidth} />
					)
				)
			}
    			
    		</div>
	
		)

} // TabBar

//	--------------------------------------------------------------------------
//
//	C L A S S   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

export default function UserManagementResults( props:	{
								tableDisplayed: boolean,
								group: string,
								nameFilter: string,
								displayPage: any
								} )
{

	//	------------------------------------------------------------
	//
	//	constants
	//
	//	------------------------------------------------------------

	// translation function
	const { t } = useTranslation();
	
	// define the tab labels.
	const tabLabels:	{
				value: string,
				index: number
				}[] =	[
						{
						value: 'Users',
						index: 0
						},
						{
						value: 'Groups',
						index: 1
						}
					]
  			
  	// state variables.
	const [sLoadingComponent, setLoadingComponent] = useState< boolean >( true );
	const [sDataList, setDataList] = useState< { username: string, email: string, name: string, created: Date, endTime: Date, lastUpdate: Date }[] >( [] );
	const [sTab, setTab] = useState< number >( 0 );
	const [sTotal, setTotal] = useState< number[] >( [ 35, 26 ] );
	const [sPage, setPage] = useState< number[] >( [ 0, 0 ] );
	const [sPages, setPages] = useState< number[] >( [ 4, 3 ] );
	const [sPageSize, setPageSize] = useState< number >( 10 );
	
	// user data.
	const [sUserData, setUserData] = useState<	{
							username: string,
							email: string,
							name: string,
							created: Date,
							endTime: Date,
							lastUpdate: Date
							}[] >( [] );
	
	// boolean to hold whether the tab drop-down menu is displayed.
	const [sTabDropdownDisplayed, setTabDropdownDisplayed] = useState<boolean>( false );

	//	------------------------------------------------------------
	//
	//	Kick off some processes once the page has loaded.
	//
	//	------------------------------------------------------------
  	
  	useEffect	( () =>
		  	{
		  	
		  		// load the data from the data-management end point of the data-management API.
		  		loadData();
		  		
				setLoadingComponent( false );
				
			}, []
			);

	//	------------------------------------------------------------
	//
	//	An asynchronous function that loads data from the data
	//	management API for a specific namespace.
	//
	//	------------------------------------------------------------
  	
  	async function loadData()
  	{
	
		//try
		//{

		//	var urlCommand: string = APIPrefix() + '/v1/data_management/list_data?';
			
			// token.
		//	urlCommand = urlCommand +	'namespace=' + props.namespace;
		//	if (props.filename !== '')
		//		urlCommand = urlCommand + '&filename=' + props.filename;

		//	try
		//	{
				
		//		const apiResult = await fetch( urlCommand, {headers: {'Content-Type': 'application/json'}, credentials: 'include'} );
		//		if (apiResult.status === 200)
		//		{
				
		//			const returnedJson = await apiResult.json();

					// get data list.
		//			var dataList: { scope: string, name: string, did_type: string, bytes: number, length: number }[] = [];
		//			if (returnedJson.data_list !== undefined)
		//				dataList = returnedJson.data_list;
					
					// update the state with the list of returned data items.
		//			setDataList( dataList );
					
		//		}
				
				// if the return code is 401 then either the data-management token or the gateway-backend
				// token has expired. we should renew them.
		//		if (apiResult.status === 401)
		//			props.renewTokens( {} );
				
		//	}
		//	catch (e)
		//	{
		//		console.log( e );
		//	}
			
      		//}
      		//catch (e)
      		//{
		//	console.log(e);
		//}
		
		const userData:	{
					username: string,
					email: string,
					name: string,
					created: Date,
					endTime: Date,
					lastUpdate: Date
					}[] =	[
							{
							username: 'a-aardvark',
							email: 'a.aardvaark@zoo.com',
							name: 'A. Aardvaark',
							created: new Date( Date.now() - 365 ),
							endTime: new Date( Date.now() + 365 ),
							lastUpdate: new Date( Date.now() - 7 )
							},
							{
							username: 'b-baboon',
							email: 'b-baboon@zoo.com',
							name: 'B. Baboon',
							created: new Date( Date.now() - 256 ),
							endTime: new Date( Date.now() + 186 ),
							lastUpdate: new Date( Date.now() - 12 )
							}
						]
  	
  	} // loadData

	//	-------------------------------------------------
	//
	//	button handler for onClick events.
	//
	//	-------------------------------------------------
	
	const onClickHandler = (event: React.MouseEvent<HTMLButtonElement>) =>
	{
	
		event.preventDefault();

		const button: HTMLButtonElement = event.currentTarget;

		// move to first page.
		if (button.name === "searchResultsFirst" && sTab >= 0)
		{
			var currentPage: number[] = Object.assign( [], sPage );
			currentPage[ sTab ] = 0;
			setPage( currentPage );
		}

		// move to previous page.
		if (button.name === "searchResultsPrevious" && sTab >= 0)
		{
			var currentPage: number[] = Object.assign( [], sPage );
			currentPage[ sTab ] = currentPage[ sTab ] - 1;
			setPage( currentPage );
		}

		// move to page before previous page.
		if (button.name === "searchResultsMinus2" && sTab >= 0)
		{
			var currentPage: number[] = Object.assign( [], sPage );
			currentPage[ sTab ] = currentPage[ sTab ] - 2;
			setPage( currentPage );
		}

		// move to previous page.
		if (button.name === "searchResultsMinus1" && sTab >= 0)
		{
			var currentPage: number[] = Object.assign( [], sPage );
			currentPage[ sTab ] = currentPage[ sTab ] - 1;
			setPage( currentPage );
		}

		// move to next page.
		if (button.name === "searchResultsPlus1" && sTab >= 0)
		{
			var currentPage: number[] = Object.assign( [], sPage );
			currentPage[ sTab ] = currentPage[ sTab ] + 1;
			setPage( currentPage );
		}

		// move to page after next page.
		if (button.name === "searchResultsPlus2" && sTab >= 0)
		{
			var currentPage: number[] = Object.assign( [], sPage );
			currentPage[ sTab ] = currentPage[ sTab ] + 2;
			setPage( currentPage );
		}

		// move to next page.
		if (button.name === "searchResultsNext" && sTab >= 0)
		{
			var currentPage: number[] = Object.assign( [], sPage );
			currentPage[ sTab ] = currentPage[ sTab ] + 1;
			setPage( currentPage );
		}

		// move to last page.
		if (button.name === "searchResultsLast" && sTab >= 0)
		{
			var currentPage: number[] = Object.assign( [], sPage );
			currentPage[ sTab ] = (sPages[ sTab ] > 0 ? sPages[ sTab ] - 1 : 0 );
			setPage( currentPage );
				
		}
		
		if (button.name === "btnTabDropdown")
			setTabDropdownDisplayed( !sTabDropdownDisplayed );
			
		if (button.name === "createUser")
			props.displayPage(	{
							displayPage: UserManagementPage.UserEditor,
							userID: -1,
							editing: true
							} );
			
		if (button.name === "createGroup")
			props.displayPage(	{
							displayPage: UserManagementPage.GroupEditor,
							groupID: -1,
							editing: true
							} );
				
		// if one of the tabs on a search results form has been pressed, then store the button name.
		const namePrefix = "btnTab_0_tab";
		if (button.name.length > namePrefix.length)
			if (button.name.slice( 0, namePrefix.length ) === namePrefix)
			{
			
				// cut the rest of the text from the button name to get the data product type.
				let resultsTab: string = button.name.slice( namePrefix.length - button.name.length );
				
				// convert to an integer.
				var tabIndex: number = -1;
				try
				{
					tabIndex = parseInt( resultsTab );
				}
				catch
				{
				}
				
				// update the state.
				if (tabIndex >= 0)
					setTab( tabIndex );
				
				// ensure the tab dropdown is closed.
				setTabDropdownDisplayed( false );
			
			}
				
	} // onClickHandler

	//	-------------------------------------------------
	//
	//	function that handles a change in the desired page size
	//
	//	-------------------------------------------------
  	
	const pageSizeOnChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) =>
  	{

		var newPageSize: number = sPageSize;
		try
		{
			newPageSize = parseInt( event.target.value );
		}
		catch
		{
		}
		
		// calculate the row indexes of the first rows.
		var firstRow: number[] = new Array( sPage.length );
		for ( let i = 0; i < sPage.length; i++ )
			firstRow[ i ] = (sPageSize * sPage[ i ]);
		
		// calculate the new page numbers, and update the state.
		var currentPage: number[] = Object.assign( [], sPage );
		var pages: number[] = Object.assign( [], sPages );
		for ( let i = 0; i < sPage.length; i++ )
		{
			currentPage[ i ] = Math.floor( firstRow[ i ] / newPageSize );
			pages[ i ] = Math.ceil( sTotal[ i ] / newPageSize );
		}
		setPage( currentPage );
		setPages( pages );
  	
  		console.log( "page size changed to " + newPageSize.toString() );
  		
  		// update state with the selected value.
  		setPageSize( newPageSize );
  	
  	} // pageSizeOnChangeHandler

	//	------------------------------------------------------------
	//
	//	Handles an update page event from the results footer.
	//
	//	------------------------------------------------------------
  	
  	const updatePage = function( props:	{
  						page: number
	  					} )
  	{
  	
  		if (sTab >= 0)
  		{
  			var currentPage: number[] = Object.assign( [], sPage );
  			currentPage[ sTab ] = props.page;
  			setPage( currentPage );
  		}
  	
  	} // updatePage

	//	------------------------------------------------------------
	//
	//	C O M P O N E N T S
	//
	//	------------------------------------------------------------

	//	------------------------------------------------------------
	//
	//	Display an icon and name for each data item.
	//
	//	------------------------------------------------------------
	
	function DataItems()
	{
	
		return	(
			<div style = {{ display: 'flex', flexDirection: 'column' }}>
			
			</div>
			)
	
	} // DataItems

	//	------------------------------------------------------------
	//
	//	Component code
	//
	//	------------------------------------------------------------
	
	return	(
	
		<div className = "user-management-table">
				
			<div className = {sLoadingComponent === true && props.tableDisplayed === true ? "search-results-table-loading" : "search-results-table-loaded"}>
				<img	className = "animated-gears"
					src = {gearsIcon}
					alt = ""
					width = "60"
					height = "60" />
				Loading
			</div>
	
			<div style = {{ flex: '0 0 10px' }} />
			
			<div style = {{ display: 'flex', flexDirection: 'row' }} >
			
	    			<div style = {{ flex: '0 0 10px' }} />
    	
		    		{/* display the 'users/groups' tab bar */}
		    		<TabBar	tabID = '0'
		    				tabLabel = {tabLabels}
		    				selectedTab = {sTab}
		    				totalCount = {sTotal}
		    				onClickHandler = {onClickHandler}
		    				tabDropdownDisplayed = {sTabDropdownDisplayed}
		    				setTabDropdownDisplayed = {setTabDropdownDisplayed}
		    				pages = {sPageSize} />
			
	    			<div style = {{ flex: '0 0 10px' }} />
				
	    			{/*
	    			//
	    			//	add user button
	    			//
	    			*/}
				<div className = "form-button-container">
					{
					sTab === 0 ?
		    	    		<ToolButton	key = {t("Create user")}
		    	    				name = "createUser"
		    	    				onClick = {onClickHandler}
		    	    				text = {t("Create user")}
		    	    				type = {ToolButtonType.PRIMARY} /> :
		    	    		<ToolButton	key = {t("Create group")}
		    	    				name = "createGroup"
		    	    				onClick = {onClickHandler}
		    	    				text = {t("Create group")}
		    	    				type = {ToolButtonType.PRIMARY} />
		    	    		}
		    	    	</div>
	    				
	    		</div>
	    			
	    		<div style = {{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
		    	
				{/* and then we have the results-per-page dropdown */}
				<div	key = {sPageSize.toString()}
					className = "search-results-per-page">{t("Results per page:")}</div>
					
				<select	className = "search-results-per-page-dropdown"
						name = "searchResultsPerPage"
						onChange = {pageSizeOnChangeHandler} >
					<option value = "10">10</option>
					<option value = "25">25</option>
					<option value = "50">50</option>
				</select>
				
				<div style = {{ flex: '0 0 10px' }} />
			
			</div>
			
			{/* display the list of data items */}
			<div	className = {sTab === 0 ? "search-results-table-holder-visible" : "search-results-table-holder-hidden"} >
				<UserManagementUsers	key = {sPageSize.toString() + sPage[ 0 ].toString()}
							page = {sPage[ 0 ]}
							pageSize = {sPageSize}
							rows = {sTotal[ 0 ]}
							displayPage = {props.displayPage} />
			</div>
			
			{/* display the list of data items */}
			<div	className = {sTab === 1 ? "search-results-table-holder-visible" : "search-results-table-holder-hidden"} >
				<UserManagementGroups	key = {sPageSize.toString() + sPage[ 1 ].toString()}
							page = {sPage[ 1 ]}
							pageSize = {sPageSize}
							rows = {sTotal[ 1 ]}
							displayPage = {props.displayPage} />
			</div>
		
			{/* the search-results footer contains the navigation buttons for moving between results pages */}
			<SearchResultsFooter	key = {sPages[ sTab ].toString() + sTotal[ sTab ].toString() + sPageSize.toString()}
						page = {sPage[ sTab ]}
						pageSize = {sPageSize}
						pages = {sPages[ sTab ]}
						rows = {sTotal[ sTab ]}
						updatePage = {updatePage} />
			
		</div>
		
		)

} // UserManagementResults
