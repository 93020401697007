import React from "react";

interface SessionToastProps {
    onLogin: () => void;
}

const SessionToast = ({ onLogin }: SessionToastProps) => (
    <div>
        <p>Your session has expired. Please log in again.</p>
        <button
          onClick={onLogin}
          //TODO: Need to move this style to .css file
          style={{
            marginTop: '5px',
            padding: '5px 10px',
            color: '#fff',
            backgroundColor: '#e70068',
            border: 'none',
            borderRadius: '3px',
            cursor: 'pointer'
          }}
        >
            Log in
        </button>
    </div>
);

export default SessionToast;