import React, { useState, useRef, Ref, useEffect } from 'react';
import './context-menu.css';
import { useTranslation } from "react-i18next";

// icons

// classes

//	--------------------------------------------------------------------------
//
//	T Y P E S
//
//	--------------------------------------------------------------------------
	
export interface ContextMenuType
{

	/*
	**	the items property is an array of context-menu rows:
	**
	**	text: the row text
	**	childMenu: the index of the sub menu from this row. -1 if there is no sub menu
	**	onMouseEnter: true if something needs to happen when the mouse is hovered over this row. i.e. if
	**			a sub menu needs to be built (props.onMouseEnter is called)
	**	onClick: true if something needs to happen when the user clicks on this row (props.onClick is called)
	**	enabled: true if the row is enabled
	*/
	items:	{
			text: string,
			childMenu: number,
			onMouseEnter: boolean,
			onClick: boolean,
			enabled: boolean
		}[],
	parentMenu: number

} // ContextMenuType

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	C L A S S   D E F I N I T I O N
//
//	--------------------------------------------------------------------------
	
export function ContextMenu( props:	{
					contextMenuDefinition: ContextMenuType[],
					menuID: number,
					onClick: any,
					onMouseEnter: any,
					dataWindowWidth: number,
					dataWindowHeight: number,
					containerScrollPosition: number,
					contextPos: { x: number, y: number }[],
					setContextPos: any,
					contextRow: number[],
					setContextRow: any,
					contextVisible: boolean[],
					setContextVisible: any,
					numContextMenus: number,
					contextMenuWidth: number
					} )
{

	//	------------------------------------------------------------
	//
	//	Calculates the position of a context menu so that it is
	//	within the bounds of the container.
	//
	//	------------------------------------------------------------
  	
  	function calculateContextMenuPosition( args:	{
  							parent:	{
  									left: number,
  									top: number,
  									right: number,
  									bottom: number,
  									},
  							child:		{
  									menuIndex: number,
  									width: number,
  									height: number
  									},
							setPos: any,
  							selectedRow: number
  							} )
  	{
  	
  		// initialise the position.
  		var x: number = args.parent.right;
  		var y: number = args.parent.top + (args.selectedRow * 30.0);
  		
  		// check if the menu if off the right-hand size of the container.
  		if (x + args.child.width > props.dataWindowWidth)
  			x = args.parent.left - args.child.width;
  		if (x < 0.0)
  			x = 0.0;

  		// check if the menu is off the bottom of the container.
  		// the window height gives the height of the visible part of the scrollable window,
  		// but the y coordinate gives the position within the whole scrollable area.
  		// we therefore need to include the container scroll position, which gives the position
  		// of the top of the visible area within the whole scrollable area.
  		if (y + args.child.height - props.containerScrollPosition > props.dataWindowHeight)
  			y = props.dataWindowHeight - args.child.height + props.containerScrollPosition;
  		if (y < props.containerScrollPosition)
  			y = props.containerScrollPosition;
  			
  		// update the position of the child menu.
  		var pos: { x: number, y: number }[] = props.contextPos.slice();
  		pos[ args.child.menuIndex ] = { x: x, y: y };
  		args.setPos( pos );
  	
  	} // calculateContextMenuPosition

	//	------------------------------------------------------------
	//
	//	Handler for clicks on move-data menu items.
	//
	//	------------------------------------------------------------
  	
  	function onClick( args:	{
  					item:	{
						text: string,
						childMenu: number,
						onMouseEnter: boolean,
						onClick: boolean,
						enabled: boolean
						},
					itemID: number
					} )
	{

		// check if we've got a handler defined.  	
  		if (args.item.onClick === true)
  		{

	  		// call the handler.
  			props.onClick(	{
  					menuID: props.menuID,
  					rowID: props.contextRow
  					} );
				
			// remove the context menu.
			props.setContextVisible( Array( props.numContextMenus ).fill( false ) );
			props.setContextRow( Array( props.numContextMenus ).fill( -1 ) );
			
		}
  	
  	} // onClick

	//	------------------------------------------------------------
	//
	//	Handler for when the pointer enters a row on the context
	//	menu.
	//
	//	------------------------------------------------------------
  	
  	function onMouseEnter( args:	{
  					item:	{
						text: string,
						childMenu: number,
						onMouseEnter: boolean,
						onClick: boolean,
						enabled: boolean
						},
					itemID: number
					} )
  	{
			
		// ensure we have a menu ID and an item ID.
		if (props.menuID > -1 && args.itemID > -1)
			
			// only update the state if the supplied item ID is not the currently selected row for this menu.
			if (props.contextRow[ props.menuID ] !== args.itemID)
			{
				
	  			// get the item description from the context-menu definition.
	  			const item:	{
						text: string,
						childMenu: number,
						onMouseEnter: any,
						onClick: any,
						enabled: boolean
						} = props.contextMenuDefinition[ props.menuID ].items[ args.itemID ];
		  	
		  		// check if this context-menu item is enabled.
		  		if (args.item.enabled === true)
		  		{
		  		
		  			// call the handler, if it exists. this may be needed to build the child menu definition.
		  			if (args.item.onMouseEnter === true)
		  				props.onMouseEnter(	{
		  							menuID: props.menuID,
		  							rowID: args.itemID
		  							} );
		  	
			  		// set the highlighted row for this menu, and clear the highlighted row for any other menus.
			  		var firstIndex = 0;
			  		if (props.contextMenuDefinition[ props.menuID ].parentMenu !== -1)
			  			firstIndex = props.contextMenuDefinition[ props.menuID ].parentMenu + 1;
			  			
					var row: number[] = props.contextRow.slice();
					var contextVisible: boolean[] = props.contextVisible.slice();
					for ( var i = firstIndex; i < props.numContextMenus; i++ )
						if (i === props.menuID)
						{
							row[ i ] = args.itemID;
							contextVisible[ i ] = true;
						}
						else
						{
							row[ i ] = -1;
							contextVisible[ i ] = false;
						}
					props.setContextRow( row );
					
					// does this menu have a child menu?
					if (args.item.childMenu !== -1)
					{
					
						const parentHeight: number = (20 + (props.contextMenuDefinition[ props.menuID ].items.length * 30));
						const childHeight: number = (20 + (props.contextMenuDefinition[ args.item.childMenu ].items.length * 30));
					
						// calculate the position of the child menu, making sure it doesn't expand the container.
						calculateContextMenuPosition(	{
										parent: 	{
												left: props.contextPos[ props.menuID ].x,
												top: props.contextPos[ props.menuID ].y,
												right: props.contextPos[ props.menuID ].x + props.contextMenuWidth,
												bottom: props.contextPos[ props.menuID ].y + parentHeight
												},
										child:	{
											menuIndex: item.childMenu,
											width: props.contextMenuWidth,
											height: childHeight
											},
										setPos: props.setContextPos,
										selectedRow: args.itemID
										} );
										
						// set the visibility of the child menu to true.
						contextVisible[ args.item.childMenu ] = true;
						
					}
					
					// update the visibility state of the menus.
					props.setContextVisible( contextVisible );
				
				}
				
			}
  	
  	} // onMouseEnter

	//	------------------------------------------------------------
	//
	//	Component code
	//
	//	------------------------------------------------------------
  	
	if (props.contextVisible[ props.menuID ] === true)
		return	(
			
			// the context menu container. the X and Y position are set here.
			<div style = {{ display: 'flex', flexDirection: 'row', position: 'absolute', left: props.contextPos[ props.menuID ].x.toString() + 'px', top: props.contextPos[ props.menuID ].y.toString() + 'px' }} >
				
				{/* the context menu. the border, background colour, width and height are set here */}
				<div	className = "context-menu-container"
					style = {{ flex: '0 0 ' + props.contextMenuWidth.toString() + 'px', height: (20 + (props.contextMenuDefinition[ props.menuID ].items.length * 30)).toString() + 'px', display: (props.contextVisible[ props.menuID ] === true ? 'flex' : 'none'), flexDirection: 'column', alignItems: 'left', margin: '0px 0px 0px 0px' }} >
					
					{/* a small gap */}
					<div style = {{ flex: '0 0 10px' }} />
					
					{/* followed by all the rows for the context menu */}
					{
						props.contextMenuDefinition[ props.menuID ].items.map
						(
							( item, index ) =>
							(
							
								//
								// context menu row:
								//
								// onMouseEnter and onClick are set here.
								// we have one row for each item in the context-menu definition.
								// if the childMenu property is not -1 then the row will include an arrow linking it the child menu.
								//
								<div	style = {{ flex: '0 0 30px', display: 'flex', flexDirection: 'row' }}
									onMouseEnter =	{(evt) => onMouseEnter( { item, itemID: index } )}
									onClick = {(evt) => onClick( { item, itemID: index } )} >
									
									<div style = {{ flex: '0 0 5px' }} />
									<div	className = "context-menu-row"
										style = {{ flex: '1 1', display: 'flex', flexDirection: 'row', borderRadius: '5px', cursor: 'default' }}
										data-row-selected = {props.contextRow[ props.menuID ] === index ? "T" : "F"}
										data-row-enabled = {props.contextMenuDefinition[ props.menuID ].items[ index ].enabled === true ? "T" : "F"} >
										<div style = {{ flex: '0 0 10px' }} />
										<div style = {{ flex: '1 1', display: 'flex', alignItems: 'center' }} >{item.text}</div>
										{item.childMenu !== -1 ? <div style = {{ flex: '0 0 auto', display: 'flex', alignItems: 'center', textAlign: 'center', fontSize: '20px' }} >▸</div> : <></>}
										<div style = {{ flex: '0 0 10px' }} />
									</div>
									<div style = {{ flex: '0 0 5px' }} />
									
								</div>
							
							)
						)
					}
					
					{/* finally another small gap at the bottom */}
					<div style = {{ flex: '0 0 10px' }} />
					
				</div>
			
			</div>
			
			)
	else
		return	(
			<></>
			)

} // ContextMenu
